import React from "react";
import styled from "styled-components";
import { Block, Container } from "../components/ui";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ArrowRightSvg from "../assets/icons/arrow-right.svg";
import IconCloud from "../assets/icons/showcase_cloud.svg";

import Mac from "../assets/mac.svg";
import ui_1 from "../assets/ui1.jpg";
import ui_2 from "../assets/ui2.jpg";
import ui_3 from "../assets/ui3.jpg";
import ui_4 from "../assets/ui4.jpg";

const Wrapper = styled(Block)`
  background: #f8f8f8;
  overflow-x: hidden;
`;

const StyledContainer = styled(Container)`
  padding-top: 60px;
  padding-bottom: 60px;
  max-width: min(85vw, 1240px);
  margin: 0 auto;
`;

const Arrow = styled(ArrowRightSvg)`
  user-select: none;
  cursor: pointer;
  transition: opacity 0.15s ease;

  border-radius: 50%;
  background: #ffbf69;
  box-shadow: 0 0px 18px 4px #ffbf6900;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    box-shadow: 0 0px 18px 4px #ffa01c2f;
  }

  ${({ $left }) =>
    $left &&
    `
    transform: rotate(180deg);
    &:hover {
      transform: rotate(180deg) scale(1.03);
    }
  `}

  ${({ $right }) =>
    $right &&
    `    
    &:hover {
      transform: scale(1.03);
    }
  `}
`;

const PreTitle = styled.h3`
  color: #2ec4b6;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 800;
  color: #1b2033;
  margin-top: 10px;
`;

const ArticlePreTitle = styled.h3`
  color: #ff9f1c;
  margin-bottom: 10px;
`;

const ArticleTitle = styled.h2`
  font-weight: 800;
  margin-top: 0;
  color: #1b2033;
  white-space: pre;
  @media (max-width: 1180px) {
    white-space: normal;
  }
`;

const ArticleSubTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #1b2033;
`;

const ArticleParagraph = styled.p`
  white-space: pre-wrap;
  color: #1b2033;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: min(13vw, 140px);
  margin-top: 110px;
  margin-bottom: 100px;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 40px;
  }
`;
const LeftColumn = styled.div`
  flex: 1.2;
  width: 100%;
  padding-bottom: 100px;

  @media (max-width: 800px) {
    padding-bottom: 0;
  }
`;
const RightColumn = styled.div`
  /* margin-right: -100px; */
  margin-right: min(-100px, calc(calc(1240px - 100vw) / 2));
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 800px) {
    margin-right: 0;
    width: 70%;
    flex-direction: column-reverse;
    gap: 30px;
  }
`;

const ImageSlider = styled.div`
  width: fit-content;
  position: relative;
  width: 100%;
  display: grid;

  & > * {
    grid-area: 1/1;
  }

  & .macbook {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transform: translate(-4px, -10px);
    overflow: visible;
  }
`;

const ImageDiv = styled.div`
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  background: url(${({ src }) => src});
  background-size: 100%;
  background-position: top center;
`;

const Arrows = styled.div`
  margin-top: 5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const SlideContainer = styled.div`
  height: 500px;
  width: 100%;
  position: relative;
  transition: height 0.3s ease;

  @media (max-width: 800px) {
    height: 380px;
  }
`;
const Slide = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

const FourColumns = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const getSlideStyle = (index, activeIndex) => {
  return {
    transform: `translate(${100 * (index - activeIndex)}%, -50%)`,
    opacity: index === activeIndex ? 1 : 0,
    userSelect: index === activeIndex ? "auto" : "none",
  };
};

const FA_Robot_Software = () => {
  const [slideIndex, setSlideIndex] = React.useState(0);
  const [slideContainerHeight, setSlideContainerHeight] = React.useState(500);
  const slideContainer = React.useRef(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (slideContainer) {
      setSlideContainerHeight(
        slideContainer.current.children.item(slideIndex).scrollHeight
      );
    }
  }, [slideContainer, slideIndex]);

  return (
    <Wrapper>
      <StyledContainer>
        <div style={{ textAlign: "center" }}>
          <PreTitle>{t("software.pre-title")}</PreTitle>
          <Title>{t("software.title")}</Title>
        </div>
        <Row>
          <LeftColumn>
            <SlideContainer
              style={{ height: slideContainerHeight }}
              ref={slideContainer}
            >
              <Slide style={getSlideStyle(0, slideIndex)}>
                <ArticlePreTitle>
                  {t("software.slide1.pre-title")}
                </ArticlePreTitle>
                <ArticleTitle>{t("software.slide1.title")}</ArticleTitle>
                <ArticleParagraph>
                  {t("software.slide1.content")}
                </ArticleParagraph>
              </Slide>
              <Slide style={getSlideStyle(1, slideIndex)}>
                <ArticlePreTitle>
                  {t("software.slide2.pre-title")}
                </ArticlePreTitle>
                <ArticleTitle>{t("software.slide2.title")}</ArticleTitle>
                <ArticleParagraph>
                  {t("software.slide2.content")}
                </ArticleParagraph>
              </Slide>
              <Slide style={getSlideStyle(2, slideIndex)}>
                <ArticlePreTitle>
                  {t("software.slide3.pre-title")}
                </ArticlePreTitle>
                <ArticleTitle>{t("software.slide3.title")}</ArticleTitle>
                <ArticleParagraph>
                  {t("software.slide3.content")}
                </ArticleParagraph>
              </Slide>
            </SlideContainer>
          </LeftColumn>
          <RightColumn>
            <ImageSlider>
              <svg viewBox="0 0 1547 1000"></svg>
              <ImageDiv
                src={ui_1}
                alt=""
                style={{ opacity: slideIndex >= 0 ? 1 : 0 }}
              />
              <ImageDiv
                src={ui_2}
                alt=""
                style={{ opacity: slideIndex >= 1 ? 1 : 0 }}
              />
              <ImageDiv
                src={ui_3}
                alt=""
                style={{ opacity: slideIndex >= 2 ? 1 : 0 }}
              />
              <Mac className="macbook" />
            </ImageSlider>

            <Arrows>
              <Arrow
                style={{ opacity: slideIndex === 0 ? 0.2 : 1 }}
                $left
                onClick={() => setSlideIndex((p) => Math.max(p - 1, 0))}
              />
              <Arrow
                style={{ opacity: slideIndex === 1 ? 0.2 : 1 }}
                // style={{ opacity: slideIndex === 2 ? 0.2 : 1 }}
                $right
                // onClick={() => setSlideIndex((p) => Math.min(p + 1, 2))}
                onClick={() => setSlideIndex((p) => Math.min(p + 1, 1))}
              />
            </Arrows>
          </RightColumn>
        </Row>
        {/* <ArticlePreTitle>
          {t("software.four-columns.pre-title")}
        </ArticlePreTitle>
        <ArticleTitle>{t("software.four-columns.title")}</ArticleTitle> */}
        {/* <FourColumns>
          <div>
            <IconCloud />
            <ArticleSubTitle>
              {t("software.four-columns.column1.title")}
            </ArticleSubTitle>
            <ArticleParagraph>
              {t("software.four-columns.column1.content")}
            </ArticleParagraph>
          </div>
          <div>
            <IconCloud />
            <ArticleSubTitle>
              {t("software.four-columns.column2.title")}
            </ArticleSubTitle>
            <ArticleParagraph>
              {t("software.four-columns.column2.content")}
            </ArticleParagraph>
          </div>
          <div>
            <IconCloud />
            <ArticleSubTitle>
              {t("software.four-columns.column3.title")}
            </ArticleSubTitle>
            <ArticleParagraph>
              {t("software.four-columns.column3.content")}
            </ArticleParagraph>
          </div>
          <div>
            <IconCloud />
            <ArticleSubTitle>
              {t("software.four-columns.column4.title")}
            </ArticleSubTitle>
            <ArticleParagraph>
              {t("software.four-columns.column4.content")}
            </ArticleParagraph>
          </div>
        </FourColumns> */}
      </StyledContainer>
    </Wrapper>
  );
};

export default FA_Robot_Software;
