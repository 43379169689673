import React, { useState } from "react";
import styled from "styled-components";
import { Block, Container, PreTitle, Title } from "../components/ui";
// import closing from "../assets/closed.jpg";
import closing from "../assets/closed2.jpg";
import glover from "../assets/glover.jpg";
import kitchen from "../assets/kitchen.jpg";
// import cloudkitchen from "../assets/cloudkitchen.jpg";
import kiosk from "../assets/kiosk.png";
import ArrowRightSvg from "../assets/icons/arrow-right.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useSwipeable } from "react-swipeable";

import closed from "../assets/icons/about/closed.png";
import deliveryMargins from "../assets/icons/about/delivery-margins.png";
import efficiency from "../assets/icons/about/efficiency.png";
import foodCost from "../assets/icons/about/food-cost.png";
import foodWaste from "../assets/icons/about/food-waste.png";
import labourShortage from "../assets/icons/about/labour-shortage.png";

import sorryClosedImage from "../assets/sorry-closed.jpg";

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  min-height: 510px;
  max-width: 1280px;
  /* padding: 0 7vw; */
  margin: 90px auto 20px;
  background: #fff;
`;

const Image = styled.div`
  width: 30%;
  background: url(${sorryClosedImage}) center center no-repeat;
  background-size: cover;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const LeftSideContent = styled.div`
  flex: 1;
  padding: 40px min(7vw, 90px);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px min(7vw, 90px);

  @media screen and (max-width: 460px) {
    grid-template-columns: 1fr;
  }

  & > div {
    display: grid;
    grid-template-columns: 35px auto;
    gap: 10px;

    & img {
      max-width: 100%;
    }

    .title {
      font-weight: 800;
      font-size: 18px;
      line-height: 1.5em;
    }

    p {
      margin-top: 0;
    }
  }
`;

const gridData = [
  {
    image: closed,
    title: "Covid Closures",
    content:
      "Over 65,000 restaurants closed in Western Europe since the start of the pandemic.",
  },
  {
    image: foodCost,
    title: "Food Cost",
    content:
      "Ingredient costs have risen by 8% in Spain over the last 2 years.",
  },
  {
    image: labourShortage,
    title: "Labour Shortage",
    content:
      "Covid will continue to dramatically change work, and restaurants are years from recovery. ",
  },
  {
    image: efficiency,
    title: "Efficiency",
    content:
      "New, untrained labour means low efficiency and consistency in food prep.",
  },
  {
    image: foodWaste,
    title: "Food Waste",
    content:
      "12% average food waste across the food sector. Thats on top of the 30% waste in agriculture. ",
  },
  {
    image: deliveryMargins,
    title: "Delivery Margins",
    content:
      "It was already tough, but labour increases are wiping out even the slimmest margins.",
  },
];

const About = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <StyledContainer>
        <LeftSideContent>
          <PreTitle style={{ textAlign: "left" }}>
            {t("about.a_pre_title")}
          </PreTitle>
          <Title style={{ textAlign: "left" }}>{t("about.a_title")}</Title>
          <Grid>
            {gridData.map((item, index) => (
              <div key={index}>
                <div className="icon">
                  <img src={item.image} alt="" />
                </div>
                <div className="content">
                  <div className="title">{item.title}</div>
                  <p>{item.content}</p>
                </div>
              </div>
            ))}
          </Grid>
        </LeftSideContent>
        <Image />
      </StyledContainer>
    </Block>
  );
};

export default About;
