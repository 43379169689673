import React from "react";
import styled from "styled-components";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const StyledSplide = styled(Splide)`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  height: 450px;

  & .splide__track {
    height: 100%;
  }
`;

const Bowl = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 50px;
  transform: translateY(-50px);
  margin-bottom: -50px;
  box-shadow: 0 20px 15px -10px #93b1ad;
`;

const HiddenWrapper = styled.div`
  overflow: hidden;
  transition: height 0.6s ease, padding 0.6s ease;
  padding-bottom: 20px;
`;

const Card = styled.div`
  background: #cbf3f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 258px;
  border-radius: 20px;
  color: #1b2033;
  padding-bottom: 10px;
  box-shadow: inset 0 0 35px 5px #c98c8c26;
`;

const RecipeSlide = styled(SplideSlide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  transition: height 0.6s ease;
  transform: translateY(30px);

  & ${HiddenWrapper} {
    height: 0px;
    padding: 0px;
  }

  &.is-active ${Card} {
    box-shadow: inset 0 0 35px 5px #c98c8c26, 0 0 20px 10px #0f121d;
  }

  &.is-active ${HiddenWrapper} {
    height: ${({ $height }) => $height || "220px"};
    padding-bottom: 20px;
  }
`;

const Title = styled.h4`
  font-size: 18px;
  text-transform: uppercase;
  margin: 20px 0 15px 0;
  font-weight: 800;
  text-align: center;
`;

const Ingredients = styled.div`
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
`;

const Info = styled.div`
  text-align: center;
  font-weight: 800;
  font-size: 14px;
`;

const Recipe = ({ data }) => {
  return (
    <RecipeSlide $height={(data.ingredients.length + 3) * 16 + 15 + 20 + "px"}>
      <Card>
        <Bowl src={data.image} alt="" />
        <Title>{data.name}</Title>
        <HiddenWrapper>
          <Ingredients>
            {data.ingredients.map((ingredient, i) => (
              <div key={i}>{ingredient}</div>
            ))}
          </Ingredients>
          <Info>{data.kcal} KCal</Info>
          {/* <Info>{data.macros} macros</Info> */}
          <Info>{data.weight} G</Info>
        </HiddenWrapper>
      </Card>
    </RecipeSlide>
  );
};

const RecipeSlider = ({ recipes }) => {
  return (
    <div>
      <StyledSplide
        options={{
          type: "loop",
          focus: "center",
          autoWidth: true,
          autoHeight: true,
          arrows: false,
          pagination: false,
          autoplay: true,
          drag: false,
          interval: 1400,
          speed: 800,
          updateOnMove: true,
          cloneStatus: false,
          pauseOnHover: false,
        }}
      >
        {recipes.map((recipe, i) => (
          <Recipe data={recipe} key={i} />
        ))}
      </StyledSplide>
    </div>
  );
};

export default RecipeSlider;
