import React from "react";
import styled from "styled-components";
import { Block, Container } from "../components/ui";
import Counter from "../components/Counter";
import { useTranslation } from "gatsby-plugin-react-i18next";
import RecipeSlider from "../components/RecipeSlider";

import ErrorSavingsIcon from "../assets/icons/error-savings.svg";
import FoodSavingsIcon from "../assets/icons/food-savings.svg";
import EmployeeWorkSavingsIcon from "../assets/icons/employee-work-savings.svg";
import OurSuccessCaseBowl from "../assets/icons/our-success-case-bowl.svg";

import bowlImage1 from "../assets/bowls/1.png";
import bowlImage2 from "../assets/bowls/2.png";
import bowlImage3 from "../assets/bowls/3.png";
import bowlImage4 from "../assets/bowls/4.png";
import bowlImage5 from "../assets/bowls/5.png";



const Wrapper1 = styled(Block)`
  background: #f8f8f8;
  overflow: visible;
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperSlide = styled(Block)`
  background: #f8f8f8;
  overflow-x: hidden;
  z-index: 1;
`;

const Wrapper2 = styled(Block)`
  background: #1b2033;
  overflow-x: hidden;
`;

const StyledContainer1 = styled(Container)`
  background: #FFBF69;
  padding-top: 60px;
  padding-bottom: 60px;
  width: min(85vw, 1280px);
  border-radius: 30px;
  margin-bottom: 90px;
  overflow-x: hidden;
`;

const Title = styled.h1`
  text-align: right;
  white-space: pre;
  font-size: min(40px,4vw);
  font-weight: 900;
  color: #2b2a44;
  margin-top: 30px;
  padding-right: 90px;

  @media (max-width: 800px) {
    text-align: center;
    font-size: 5vw;
    padding-right: 0px;
  }
`; 

const Paragraph = styled.p`
  text-align: right;
  white-space: pre-wrap;
  color: #1b2033;
  padding-right: 90px;
  margin-bottom: 70px;
  font-size: min(17px,2vw);
  word-spacing: 2px;
  line-height: 22px;

  @media (max-width: 800px) {
    text-align: center;
    font-size: 10px;
    line-height: 15px;
    padding-right: 0px;
  }
`;

const StyledContainer2 = styled(Container)`
  padding-top: 60px;
  padding-bottom: 60px;
  max-width: 1280px;
`;



const CombinationsHeader1 = styled.span`
  color: #fff;
  text-align: center;
  font-size: 70px;
  margin: 5px;
  font-weight: 800;
  line-height: 120%;

  @media (max-width: 480px) {
    font-size: 50px;
  }
`;

const CombinationsHeader2 = styled.span`
  color: #fff;
  text-align: center;
  font-size: 50px;
  margin: 5px;
  font-weight: 800;

  @media (max-width: 480px) {
    font-size: 40px;
  }
`;

const CombinationsHeader3 = styled.div`
  color: #fff;
  text-align: center;
  font-size: 16px;
  margin: 5px auto;
  width: 30vw;
  min-width: min(80vw, 400px);
  max-width: 400px;
  font-weight: 800;
`;

const ImportantNumberTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;

  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;

  white-space: normal;

  @media (max-width: 1280px) {
    white-space: pre;
  }

  color: #fffffe;
`;

const ImportantNumbersGrid = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }

  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

const ImportantNumberContainer = styled.div`
  width: 265px;
  text-align: center;
  margin-bottom: 60px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  @media (max-width: 720px) {
    width: 40%;
  }

  @media (max-width: 480px) {
    width: 80%;
  }
`;

const ImportantNumber = styled.div`
  font-weight: 800;
  font-size: min(55px, max(6vw, 50px));
  line-height: 100px;
  text-align: center;
  text-transform: capitalize;
  color: #1b2033;
  /* height: 65px; */

  > span {
    line-height: 0;
  }

  @media (max-width: 720px) {
    font-size: 60px;
    line-height: 60px;
  }
`;



const OurSuccessCase = () => {
    const { t } = useTranslation();
  
    const recipes = [
      {
        image: bowlImage1,
        name: t("our_success_case.bowls_slider.bowl_1.title"),
        ingredients: t("our_success_case.bowls_slider.bowl_1.ingredients")
          .split("|")
          .map((v) => v.trim()),
        kcal: 588,
        weight: 400,
      },
      {
        image: bowlImage2,
        name: t("our_success_case.bowls_slider.bowl_2.title"),
        ingredients: t("our_success_case.bowls_slider.bowl_2.ingredients")
          .split("|")
          .map((v) => v.trim()),
        kcal: 908,
        weight: 430,
      },
      {
        image: bowlImage3,
        name: t("our_success_case.bowls_slider.bowl_3.title"),
        ingredients: t("our_success_case.bowls_slider.bowl_3.ingredients")
          .split("|")
          .map((v) => v.trim()),
        kcal: 908,
        weight: 430,
      },
      {
        image: bowlImage4,
        name: t("our_success_case.bowls_slider.bowl_4.title"),
        ingredients: t("our_success_case.bowls_slider.bowl_4.ingredients")
          .split("|")
          .map((v) => v.trim()),
        kcal: 908,
        weight: 430,
      },
      {
        image: bowlImage5,
        name: t("our_success_case.bowls_slider.bowl_5.title"),
        ingredients: t("our_success_case.bowls_slider.bowl_5.ingredients")
          .split("|")
          .map((v) => v.trim()),
        kcal: 908,
        weight: 430,
      },
    ];

    return (
      <div>
      <Wrapper1 id="success">

        <OurSuccessCaseBowl style={{ position: "absolute", transform: "translateX(-725px)", zIndex: "2" }}/>

        <WrapperSlide>

          <h3 style={{ color: "#ffbf69", textAlign: "center" ,   letterSpacing: "4px" , paddingBottom: "25px"}}>
            {t("our_success_case.header")}
          </h3>

          <StyledContainer1>
            <Title>
              {t("our_success_case.title")}
            </Title>

            <Paragraph>
            {t("our_success_case.text")}
            </Paragraph>

            <ImportantNumbersGrid>

              <ImportantNumberContainer>
                <ErrorSavingsIcon />
                <ImportantNumber>
                  <Counter end={829} duration={0.3} />
                  <span style={{ fontSize: "min(55px, max(6vw, 50px))"}}> €</span>
                </ImportantNumber>
                <ImportantNumberTitle>
                  {t("our_success_case.numbers.error_savings")}
                </ImportantNumberTitle>
              </ImportantNumberContainer>

              <ImportantNumberContainer>
                <FoodSavingsIcon />
                <ImportantNumber>
                  <Counter end={1052} duration={0.3} />
                  <span style={{ fontSize: "min(55px, max(6vw, 50px))"}}> €</span>
                </ImportantNumber>
                <ImportantNumberTitle>
                  {t("our_success_case.numbers.food_savings")}
                </ImportantNumberTitle>
              </ImportantNumberContainer>

              <ImportantNumberContainer>
                <EmployeeWorkSavingsIcon />
                <ImportantNumber>
                  <Counter end={1930} duration={0.3}/>
                  <span style={{ fontSize: "min(55px, max(6vw, 50px))"}}> €</span>
                </ImportantNumber>
                <ImportantNumberTitle>
                  {t("our_success_case.numbers.employee_savings")}
                </ImportantNumberTitle>
              </ImportantNumberContainer>

            </ImportantNumbersGrid>  
          </StyledContainer1>

        </WrapperSlide>

      </Wrapper1>

      <Wrapper2>
        <StyledContainer2>
          <h3 style={{ color: "#ffbf69", textAlign: "center" }}>
            {t("our_success_case.bowls.what_does_that_mean")}
          </h3>
          <h2 style={{ textAlign: "center", marginTop: 10 }}>
            <CombinationsHeader1>
              <Counter
                start={1000000}
                end={14000000}
                duration={0.4}
                separator=","
                suffix=""
              />
            </CombinationsHeader1>
            <br />
            <CombinationsHeader2>
              {t("our_success_case.bowls.possible_combinations")}
            </CombinationsHeader2>
          </h2>
          <CombinationsHeader3>
            {t("our_success_case.bowls.combinations_subtitle")}
          </CombinationsHeader3>
          <RecipeSlider recipes={recipes} />
        </StyledContainer2>
      </Wrapper2>
      </div>

    );
  };
  
  export default OurSuccessCase;
  