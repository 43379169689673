import React from "react";
import styled from "styled-components";
import { Block, Container } from "../components/ui";
import Counter from "../components/Counter";
import HowItWorksSlider from "../components/HowItWorksSlider";
import { useTranslation } from "gatsby-plugin-react-i18next";
import RecipeSlider from "../components/RecipeSlider";

import IngPerMealIcon from "../assets/icons/avg-ing-per-meal.svg";
import BowlsCompletedIcon from "../assets/icons/bowls-completed.svg";
import FoodWastePercentageIcon from "../assets/icons/food-waste-percentage.svg";
import OrdersPerMinuteIcon from "../assets/icons/orders-per-minute.svg";

import bowlImage1 from "../assets/bowls/1.png";
import bowlImage2 from "../assets/bowls/2.png";
import bowlImage3 from "../assets/bowls/3.png";
import bowlImage4 from "../assets/bowls/4.png";
import bowlImage5 from "../assets/bowls/5.png";

const Wrapper = styled(Block)`
  background: #1b2033;
  overflow-x: hidden;
`;

const StyledContainer = styled(Container)`
  padding-top: 60px;
  padding-bottom: 60px;
  max-width: 1280px;
`;

const PreTitle = styled.h3`
  color: #ffbf69;
  margin-bottom: 10px;

  @media (max-width: 850px) {
    text-align: center;
  }
`;

const Title = styled.h2`
  color: #fff;
  margin-top: 10px;
  font-weight: 800;

  @media (max-width: 850px) {
    text-align: center;
  }
`;

const ImportantNumbersHeader = styled.h3`
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 50px;
  font-weight: 500;
`;

const ImportantNumberTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  white-space: normal;

  @media (max-width: 1280px) {
    white-space: pre;
  }

  color: #ffbf69;
`;

const ImportantNumbersGrid = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 100px;

  @media (max-width: 1280px) {
    flex-wrap: wrap;
  }

  @media (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

const ImportantNumberContainer = styled.div`
  width: 25%;
  text-align: center;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  @media (max-width: 720px) {
    width: 40%;
  }

  @media (max-width: 480px) {
    width: 80%;
  }
`;

const ImportantNumber = styled.div`
  font-weight: 800;
  font-size: min(82px, max(6vw, 50px));
  line-height: 4vw;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  /* height: 65px; */

  > span {
    line-height: 0;
  }

  @media (max-width: 720px) {
    font-size: 60px;
    line-height: 60px;
  }
`;

const CombinationsHeader1 = styled.span`
  color: #fff;
  text-align: center;
  font-size: 70px;
  margin: 5px;
  font-weight: 800;
  line-height: 120%;

  @media (max-width: 480px) {
    font-size: 50px;
  }
`;

const CombinationsHeader2 = styled.span`
  color: #fff;
  text-align: center;
  font-size: 50px;
  margin: 5px;
  font-weight: 800;

  @media (max-width: 480px) {
    font-size: 40px;
  }
`;

const CombinationsHeader3 = styled.div`
  color: #fff;
  text-align: center;
  font-size: 16px;
  margin: 5px auto;
  width: 30vw;
  min-width: min(80vw, 400px);
  max-width: 400px;
  font-weight: 800;
`;

const HowItWorks = () => {
  const { t } = useTranslation();

  const recipes = [
    {
      image: bowlImage1,
      name: t("how_it_works.bowls_slider.bowl_1.title"),
      ingredients: t("how_it_works.bowls_slider.bowl_1.ingredients")
        .split("|")
        .map((v) => v.trim()),
      kcal: 588,
      weight: 400,
    },
    {
      image: bowlImage2,
      name: t("how_it_works.bowls_slider.bowl_2.title"),
      ingredients: t("how_it_works.bowls_slider.bowl_2.ingredients")
        .split("|")
        .map((v) => v.trim()),
      kcal: 908,
      weight: 430,
    },
    {
      image: bowlImage3,
      name: t("how_it_works.bowls_slider.bowl_3.title"),
      ingredients: t("how_it_works.bowls_slider.bowl_3.ingredients")
        .split("|")
        .map((v) => v.trim()),
      kcal: 908,
      weight: 430,
    },
    {
      image: bowlImage4,
      name: t("how_it_works.bowls_slider.bowl_4.title"),
      ingredients: t("how_it_works.bowls_slider.bowl_4.ingredients")
        .split("|")
        .map((v) => v.trim()),
      kcal: 908,
      weight: 430,
    },
    {
      image: bowlImage5,
      name: t("how_it_works.bowls_slider.bowl_5.title"),
      ingredients: t("how_it_works.bowls_slider.bowl_5.ingredients")
        .split("|")
        .map((v) => v.trim()),
      kcal: 908,
      weight: 430,
    },
  ];

  return (
    <Wrapper>
      <StyledContainer>
        {/* <div style={{ textAlign: "center" }}>
          <PreTitle>{t("how_it_works.pre_title")}</PreTitle>
          <Title>{t("how_it_works.title")}</Title>
        </div>
        <HowItWorksSlider /> */}
        <ImportantNumbersHeader>
          {t("how_it_works.numbers.title")}
        </ImportantNumbersHeader>

        <ImportantNumbersGrid>
          <ImportantNumberContainer>
            <OrdersPerMinuteIcon />
            <ImportantNumber>
              <Counter end={12} duration={0.3} />
            </ImportantNumber>
            <ImportantNumberTitle>
              {t("how_it_works.numbers.orders_per_minute")}
            </ImportantNumberTitle>
          </ImportantNumberContainer>
          <ImportantNumberContainer>
            <BowlsCompletedIcon />
            <ImportantNumber>
              <Counter end={4200} duration={0.3} />
            </ImportantNumber>
            <ImportantNumberTitle>
              {t("how_it_works.numbers.bowls_completed")}
            </ImportantNumberTitle>
          </ImportantNumberContainer>
          <ImportantNumberContainer>
            <FoodWastePercentageIcon />
            <ImportantNumber>
              <Counter end={5} duration={0.3} decimals={1} />
              <span style={{ fontSize: 40 }}>%</span>
            </ImportantNumber>
            <ImportantNumberTitle>
              {t("how_it_works.numbers.food_waste_percentage")}
              <br />
              {t("how_it_works.numbers.restaurant_average")}
            </ImportantNumberTitle>
          </ImportantNumberContainer>
          <ImportantNumberContainer>
            <IngPerMealIcon />
            <ImportantNumber>
              <Counter end={6} duration={0.3} />
            </ImportantNumber>
            <ImportantNumberTitle>
              {t("how_it_works.numbers.average_ing_per_bowl")}
            </ImportantNumberTitle>
          </ImportantNumberContainer>
        </ImportantNumbersGrid>

        {/* <ImportantNumberTitle> */}
        <h3 style={{ color: "#ffbf69", textAlign: "center" }}>
          {t("how_it_works.bowls.what_does_that_mean")}
        </h3>
        {/* </ImportantNumberTitle> */}
        <h2 style={{ textAlign: "center", marginTop: 10 }}>
          <CombinationsHeader1>
            <Counter
              start={106}
              end={1500}
              duration={0.3}
              separator=","
              suffix=""
            />
          </CombinationsHeader1>
          <br />
          <CombinationsHeader2>
            {t("how_it_works.bowls.possible_combinations")}
          </CombinationsHeader2>
        </h2>
        <CombinationsHeader3>
          {t("how_it_works.bowls.combinations_subtitle")}
        </CombinationsHeader3>
        <RecipeSlider recipes={recipes} />
      </StyledContainer>
    </Wrapper>
  );
};

export default HowItWorks;
