import React, { useState } from "react";
import styled from "styled-components";
import { Block, Container} from "../components/ui";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Check from "../assets/icons/check.svg";

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 500px;
  max-width: 1280px;
  margin: 20px auto 20px;
  padding: 40px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  overflow: visible;
  gap: min(4vw, 50px);

  padding: 0 min(5vw, 70px);

  @media (max-width: 880px) {
    flex-direction: column;
  }
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 800;
  color: #1b2033;
  margin-top: 10px;
  text-align: center;
`;

const ArticleParagraph = styled.p`
  letter-spacing: 2px;
  white-space: pre-wrap;
  color: #1b2033;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.5;
`;

const BulletColumn = styled.div`
  margin-left: 180px;

  @media (max-width: 880px) {
    margin-left: 0px;
  }
`;

const BulletPoint = styled(Container)`
  display: flex;
  justify-content: left;
  padding: 5px 10px;
  margin: 25px 0px;
  min-width: 275px;
  background-color: #CBF3F0;
  border-radius: 10px;
`;

const BulletText = styled.p`
  padding-left: 10px;
  font-size: 25px;
  font-weight: 600;
`;



const Intro = () => {
  const { t } = useTranslation();
  const myString = "Hola, mundo!";

  return (
    <Block>
      <StyledContainer>
        <Title>{t("intro.title")}</Title>
        <Row>
          <ArticleParagraph>
              Discover <span style={{color: "#2EC4B6"}}>Lex</span>, the fully equipped robot that is going 
              to revolutionize fast food with its power to make <span style={{color: "#2EC4B6"}}>tasty
              and aesthetic bowls with up to 16 ingredients and 8 sauces</span>. Capable of producing all
              sorts of bowls, Lex will be your best ally in kitchens and restaurants, empowering your employees work.
          </ArticleParagraph>
          <BulletColumn>
            <BulletPoint>
              <BulletText>Refrigeration</BulletText>
              <Check style = {{transform: "translateY(20px) translateX(48px)"}}/>
            </BulletPoint>
            <BulletPoint>
              <BulletText>Plug and play</BulletText>
              <Check style = {{transform: "translateY(20px) translateX(41px)"}}/>
            </BulletPoint>
            <BulletPoint>
              <BulletText>Intuitive control</BulletText>
              <Check style = {{transform: "translateY(20px) translateX(18px)"}}/>
            </BulletPoint>
          </BulletColumn>
        </Row>
      </StyledContainer>
    </Block>
  );
};

export default Intro;
