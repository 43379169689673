import React from "react";
import styled from "styled-components";
import { Block, Container } from "../components/ui";
import { useTranslation } from "gatsby-plugin-react-i18next";
import JourneySlider from "../components/JourneySlider";

const Wrapper = styled(Block)`
  background: #ffbf69;
  z-index: 0 !important;
`;

const StyledContainer = styled(Container)`
  padding-top: 60px;
  padding-bottom: 60px;
  max-width: min(85vw, 1240px);
  margin: 0 auto;
`;

const PreTitle = styled.h3`
  color: #1b2033;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 800;
  color: #1b2033;
  margin-top: 10px;
`;

const Journey = () => {
  const { t } = useTranslation();
  return (
    <Wrapper id="about">
      <StyledContainer>
        <JourneySlider
          header={
            <>
              <PreTitle>{t("journey.pre_title")}</PreTitle>
              <Title>{t("journey.title")}</Title>
            </>
          }
          snappyScroll={true}
          slides={[
            {
              year: t("journey.slider.slide_1.year"),
              title: t("journey.slider.slide_1.title"),
              content: t("journey.slider.slide_1.content"),
            },
            {
              year: t("journey.slider.slide_2.year"),
              title: t("journey.slider.slide_2.title"),
              content: t("journey.slider.slide_2.content"),
            },
            {
              year: t("journey.slider.slide_3.year"),
              title: t("journey.slider.slide_3.title"),
              content: t("journey.slider.slide_3.content"),
            },
            {
              year: t("journey.slider.slide_4.year"),
              title: t("journey.slider.slide_4.title"),
              content: t("journey.slider.slide_4.content"),
            },
            {
              year: t("journey.slider.slide_5.year"),
              title: t("journey.slider.slide_5.title"),
              content: t("journey.slider.slide_5.content"),
            },
            {
              year: t("journey.slider.slide_6.year"),
              title: t("journey.slider.slide_6.title"),
              content: t("journey.slider.slide_6.content"),
            },
            {
              year: t("journey.slider.slide_7.year"),
              title: t("journey.slider.slide_7.title"),
              content: t("journey.slider.slide_7.content"),
            },
            {
              year: t("journey.slider.slide_8.year"),
              title: t("journey.slider.slide_8.title"),
              content: t("journey.slider.slide_8.content"),
            },
          ]}
        />
      </StyledContainer>
    </Wrapper>
  );
};

export default Journey;
