import React from "react";
import styled from "styled-components";
import { Block, Container, Button } from "../components/ui";
import background from "../assets/landing-page.jpg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Wrapper = styled(Block)`
  overflow-y: auto;
  background: #1b2033 url(${background}) no-repeat center center;
  background-size: cover;
  background-position-x: 40%;
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 760px;
  max-width: 1440px;
  padding: 0 7vw;
  margin: 0 auto;

  & button {
    margin-right: 10px;
  }
`;

const PreTitle = styled.h2`
  font-family: Gilroy;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  letter-spacing: 0;

  color: #cbf3f0;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  color: #fff;
  margin-top: 0;
  white-space: pre;
  font-weight: 800;

  @media (max-width: 710px) {
    white-space: normal;
  }

  @media (max-width: 550px) {
    font-size: 40px;
    white-space: pre;
  }

  @media (max-width: 430px) {
    white-space: normal;
  }
`;

const Hero = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledContainer>
        <PreTitle>{t("landing.pretitle")}</PreTitle>
        <Title>{t("landing.title")}</Title>
        <div>
          {/* <Button primary>{t("landing.button_invest")}</Button>
          <Button outline>{t("landing.button_learn")}</Button> */}
        </div>
      </StyledContainer>
    </Wrapper>
  );
};

export default Hero;