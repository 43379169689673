import React, { useState } from "react";
import styled from "styled-components";
import { Block, Button } from "../components/ui";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { validate } from "react-email-validator";
import axios from "axios";

import keynote from "../assets/WeTechFood_Deck_En.pdf";

const Wrapper = styled(Block)`
  background: #fff;
  min-height: 400px;
  padding-bottom: 100px;
  padding-top: 30px;
`;

const Card = styled.div`
  min-height: 360px;
  width: 90vw;
  max-width: 1240px;
  background: #1b2033;
  margin: 0 auto;
  border-radius: 18px;
  padding: 70px min(70px, 5vw);
`;

const PreTitle = styled.h3`
  color: #ffbf69;
  margin-bottom: 10px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 800;
  color: #ffffff;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  white-space: pre-wrap;
  color: #ffffff;
  text-align: center;
  margin-top: 0px;
`;

const InputRow = styled.div`
  display: flex;
  height: 48px;
  border-radius: 24px;
  /* overflow: hidden; */
  background: #fff;
  max-width: 420px;
  margin: 0 auto;

  & input {
    flex: 1;
    /* margin: -2px; */
    border: none;
    padding: 0 5px 0 30px;
    min-width: 50px;
    border-radius: 24px;
    max-height: 100%;

    &:focus-visible,
    &:focus {
      outline: 0px solid transparent;
    }
  }

  & button {
    min-width: 150px;
    border-radius: 28px;
    background: #ff9f1c;
    color: #fff;
    border: none;
  }

  ${({ error }) =>
    error !== "" &&
    `    
    box-shadow : 0 0 2px 2px #ff5151;

    &:after {
      content: "${error}";
      position: absolute;
      left: 50%;
      transform: translate(-50%, 66px);
      color: #ff5151;
    }

  `}
`;

const Loader = styled.div`
  margin: 0 auto;
  font-size: 2.2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: rotateAnimation 1.1s infinite linear;

  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  @-webkit-keyframes rotateAnimation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotateAnimation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const CallToActionSmall = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const downloadKeynote = () => {
    const filename = "WeTechFood-Deck-2023.pdf";
    const dataUrl = keynote; // imported at the top of the document

    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = filename;
    link.click();
  };

  const submitHandler = async (e) => {
    if (validate(email)) {
      setEmailError("");
      setIsLoading(true);
      try {
        await axios.post("/api/download-keynote", {
          email,
        });
        downloadKeynote();
        setThankYouMessage("Thanks! Your download should start in a moment.");
      } catch (e) {
        console.log(e);
        setThankYouMessage(
          "Sorry! There's been a problem. Please try again later."
        );
      }

      setIsLoading(false);
    } else if (email === "") {
      setEmailError("Please submit your email");
    } else {
      setEmailError("Please submit a valid email");
    }
  };

  return (
    <Wrapper id="invest">
      <Card>
        <PreTitle>{t("call_to_action_email.pre_title")}</PreTitle>
        <Title>{t("call_to_action_email.title")}</Title>
        <Paragraph>{t("call_to_action_email.sub_title")}</Paragraph>
        {thankYouMessage === "" ? (
          <InputRow error={emailError}>
            <input
              type="email"
              placeholder={t("call_to_action_email.email_placeholder")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && submitHandler()}
            />
            <Button primary onClick={submitHandler} disabled={isLoading}>
              {isLoading ? (
                <Loader />
              ) : (
                t("call_to_action_email.button_download")
              )}
            </Button>
          </InputRow>
        ) : (
          <PreTitle
            style={{
              marginTop: "31px",
              fontWeight: 400,
              textTransform: "none",
            }}
          >
            {thankYouMessage}
          </PreTitle>
        )}
      </Card>
    </Wrapper>
  );
};

export default CallToActionSmall;
