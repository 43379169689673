import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
// import { graphql, Link as GatsbyLink } from "gatsby";
import "./index.css";
import Header from "../sections/A_Header";
import Hero from "../sections/B_Hero";
//
import CallToActionSmall from "../sections/C_CTA_Small";
import About from "../sections/D_About";
import HowItWorks from "../sections/E_How_It_Works";
import Automate from "../sections/F_Robot_Showcase";
import Software from "../sections/Fa_Robot_Software";
import Journey from "../sections/G_Journey";
import Testimonials from "../sections/H_Testimonials";
import Team from "../sections/I_Team";
import CallToActionEmail from "../sections/J_CTA_Email_Form";
import Footer from "../sections/K_Footer";
import Layout from "../components/Layout";
import RecipeSlider from "../components/RecipeSlider";
import Values from "../sections/Values";
import OurSuccessCase from "../sections/M_Our_Success_Case";
import Intro from "../sections/N_Intro";

const Wrapper = styled.div``;

const BlueWrapper = styled.div`
  background: #1b2033;
  overflow: hidden;
`;

const IndexPage = () => {
  // const { t } = useTranslation();
  return (
    <Layout pageTitle="WeTechFood">
      <Wrapper id="home">
        <Header />
        <Hero />
        <Intro />
        {/* <CallToActionSmall /> */}
        <Automate />
        <Software />
        <OurSuccessCase />
        {/* <BlueWrapper><RecipeSlider /></BlueWrapper> */}
        <Testimonials />
        <Values />
        <Team />
        <Footer />
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
