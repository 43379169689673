import React from "react";
import styled from "styled-components";
import { Container, Block, Button } from "../components/ui";
import background from "../assets/cta-meals.png";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Wrapper = styled(Block)`
  background: #1b2033 url(${background});
  background-repeat: no-repeat;
  background-size: contain;
  height: 144px;
  min-height: 144px;
  transition: background-position 0.2s ease;

  @media (max-width: 1400px) {
    background-position: -100px 0;
  }
  @media (max-width: 1280px) {
    background-position: -200px 0;
  }
  @media (max-width: 1120px) {
    background-position: -1000px 0;
  }

  @media (max-width: 840px) {
    height: auto;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  white-space: nowrap;

  @media (max-width: 840px) {
    flex-direction: column;
    white-space: normal;
    padding-bottom: 20px;
    text-align: center;
  }
`;

const Spacer = styled.div`
  width: 380px;

  @media (max-width: 1400px) {
    width: 240px;
  }
  @media (max-width: 1280px) {
    width: 100px;
  }
  @media (max-width: 1120px) {
    display: none;
  }
`;

const CallToActionSmall = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <StyledContainer>
        <Spacer />
        <h2 style={{ color: "#fff", fontWeight: 500 }}>
          {t("call_to_action_small.title")}
        </h2>
        <Button solid large as="a" href="https://google.com">
          {t("call_to_action_small.button_try")}
        </Button>
      </StyledContainer>
    </Wrapper>
  );
};

export default CallToActionSmall;
