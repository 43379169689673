import React, { useState } from "react";
import styled from "styled-components";
// import howItWorks from "../assets/how-it-works.png";
import ArrowRightSvg from "../assets/icons/arrow-right.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Player } from "@lottiefiles/react-lottie-player";

// import Lottie from "react-lottie";
import * as order from "../assets/lottie/wtf_order.json";
import * as assemble from "../assets/lottie/wtf_prepare.json";
import * as delivery from "../assets/lottie/wtf_delivery.json";

const Slider = styled.div`
  position: relative;
  display: flex;
  overflow-x: hidden;
`;

const ImageRow = styled.div`
  padding: 2px;
  display: flex;
  min-width: 100%;
  align-items: center;
  transition: transform 0.3s ease;
  overflow: hidden;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const Caption = styled.h4`
  color: #fff;
  max-width: 45vw;
  padding: 3vw;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.02em;

  @media (max-width: 850px) {
    flex-direction: column;
    max-width: 100vw;
    text-align: center;
  }
`;

const Bullets = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: center;
  padding-bottom: 5vw;
  transform: translateX(0px);
  right: auto;

  & > button {
    width: 10px;
    height: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-width: 0;
    padding: 0 !important;

    transition: transform 0.3s ease;
    transform: scale(1);

    &:hover {
      transform: scale(1.5);
    }

    &:nth-child(${({ $slideIndex }) => $slideIndex + 1}) {
      background: rgba(255, 255, 255, 0.6);
    }
  }
`;

const Arrow = styled(ArrowRightSvg)`
  user-select: none;
  position: absolute;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
  /* border: 5px solid #1b2033; */
  border-radius: 50%;

  ${({ $left }) =>
    $left &&
    `
  top: 30%;
  left: max(-5vw, -60px);
  transform: rotate(180deg);
  `}

  ${({ $right }) =>
    $right &&
    `
  top: 30%;
  right: max(-5vw, -60px);
  `}
`;

const OuterWrapper = styled.div`
  position: relative;
  margin-bottom: 100px;
  max-width: 1280px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const HowItWorksSlider = () => {
  const { t } = useTranslation();
  const [slideIndex, setSlideIndex] = useState(0);
  return (
    <OuterWrapper>
      <Arrow
        $left
        onClick={() => setSlideIndex((p) => Math.max(p - 1, 0))}
        style={{ opacity: slideIndex === 0 ? 0 : 1 }}
      />
      <Arrow
        $right
        onClick={() => setSlideIndex((p) => Math.min(p + 1, 2))}
        style={{ opacity: slideIndex === 2 ? 0 : 1 }}
      />
      <Wrapper>
        <Slider>
          <ImageRow style={{ transform: `translateX(${-slideIndex * 100}%)` }}>
            <Player
              autoplay
              loop
              src={order}
              style={{ height: "300px", width: "300px" }}
            />

            {/* <img src={howItWorks} alt="" style={{ width: 400 }} /> */}
            <Caption>{t("how_it_works.image_caption1")}</Caption>
          </ImageRow>
          <ImageRow style={{ transform: `translateX(${-slideIndex * 100}%)` }}>
            {/* <img src={howItWorks} alt="" style={{ width: 400 }} /> */}
            <Player
              autoplay
              loop
              src={assemble}
              style={{ height: "300px", width: "300px" }}
            />
            <Caption>{t("how_it_works.image_caption2")}</Caption>
          </ImageRow>
          <ImageRow style={{ transform: `translateX(${-slideIndex * 100}%)` }}>
            {/* <img src={howItWorks} alt="" style={{ width: 400 }} /> */}
            <Player
              autoplay
              loop
              src={delivery}
              style={{ height: "300px", width: "300px" }}
            />
            <Caption>{t("how_it_works.image_caption3")}</Caption>
          </ImageRow>
        </Slider>
        <Bullets $slideIndex={slideIndex}>
          <button
            aria-label="Go to slide 1"
            onClick={() => setSlideIndex(0)}
          ></button>
          <button
            aria-label="Go to slide 2"
            onClick={() => setSlideIndex(1)}
          ></button>
          <button
            aria-label="Go to slide 3"
            onClick={() => setSlideIndex(2)}
          ></button>
        </Bullets>
      </Wrapper>
    </OuterWrapper>
  );
};

export default HowItWorksSlider;
