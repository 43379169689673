import React, { useState } from "react";
import styled from "styled-components";
import { Block, Container } from "../components/ui";
import testimonial1 from "../assets/testimonial_2.jpg";
import testimonial2 from "../assets/testimonial_1.jpg";
import testimonial3 from "../assets/testimonial_3.jpeg";
import testimonial4 from "../assets/testimonial_4.jpg";
import ArrowRightSvg from "../assets/icons/arrow-right.svg";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Wrapper1 = styled(Block)`
  background: #ffbf69;
  min-height: 150px;
`;

const Header = styled.div`
  padding-top: 45px;
  padding-bottom: 235px;
  margin: 0 auto;
  max-width: min(85vw, 1240px);
  transition: height 0.3s ease;
`;

const PreTitle = styled.h3`
  color: #1b2033;
  margin-bottom: 25px;
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 800;
  color: #1b2033;
  margin-top: 10px;
`;

const Wrapper2 = styled(Block)`
  background: #1b2033;
  min-height: 400px;
  overflow: visible;
`;

const TestimonialWrapper = styled.div`
  display: flex;
  background: #fff;
  /* height: 520px; */
  border-radius: 18px;
  transform: translateY(-130px);
  max-width: 1240px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  @media (max-width: 1280px) {
    height: auto;
    /* min-height: 520px; */
  }
`;

const Square = styled.div`
  width: 520px;
  max-width: 20vw;
  min-width: 200px;
  /* min-height: 520px; */
  ${({ image }) =>
    image && `background: #d3d3d3 url(${image}) no-repeat center center;`}
  background-size: cover;

  @media (max-width: 960px) {
    width: 100%;
    max-width: 100%;
    height: 60vw;
  }
`;

const Row = styled.div`
  display: flex;
  min-width: 100%;
  transition: transform 0.3s ease;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 30px min(90px, 7vw);
  padding-bottom: 50px; //min(113px, 9vw);
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h3 {
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #2ec4b6;
    margin-bottom: 10px;
  }
  & > h2 {
    font-weight: 800;
    font-size: 40px;
    text-transform: capitalize;
    color: #1b2033;
    margin-top: 0;
    margin-bottom: 10px;
  }
  & > p {
    font-size: 20px;
    line-height: 24px;
    color: #1b2033;
    margin-top: 0;
    margin-bottom: 15px;
  }
  & > p.small {
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: #1b2033;
    white-space: pre-wrap;
    margin-top: 16px;
    line-height: 26px;
  }
`;

const Arrow = styled(ArrowRightSvg)`
  user-select: none;
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  transition: opacity 0.15s ease;

  border-radius: 50%;
  background: #ffbf69;
  box-shadow: 0 0px 18px 4px #ffbf6900;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    box-shadow: 0 0px 18px 4px #ffa01c2f;
  }

  ${({ $left }) =>
    $left &&
    `
    position: absolute;
    top: 100%;
    right: 100px;
    transform: translateY(-70px) rotate(180deg);

    &:hover {
      transform: translateY(-70px) rotate(180deg) scale(1.03);
    }
  `}

  ${({ $right }) =>
    $right &&
    `
    position: absolute;
    top: 100%;
    transform: translateY(-70px) ;
    right: 40px;  
    
    &:hover {
      transform: translateY(-70px) scale(1.03);
    }
  `}
`;

const Testimonials = () => {
  const { t } = useTranslation();
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <>
      <Wrapper1>
        <Header>
          <PreTitle>{t("testimonials.pretitle")}</PreTitle>
          <Title>{t("testimonials.title")}</Title>
        </Header>
      </Wrapper1>
      <Wrapper2>
        <Container>
          <TestimonialWrapper id="testimonials">
            <Row style={{ transform: `translateX(${-slideIndex * 100}%)` }}>
              <Square image={testimonial1} />
              <Content>
                <h3>{t("testimonials.header")}</h3>
                <h2>{t("testimonials.person_2.brand_name")}</h2>
                <p>{t("testimonials.person_2.quote")}</p>
                <p className="small">
                  {t("testimonials.person_2.qualifications")}
                </p>
              </Content>
            </Row>
            <Row style={{ transform: `translateX(${-slideIndex * 100}%)` }}>
              <Square image={testimonial2} />
              <Content>
                <h3>{t("testimonials.header")}</h3>
                <h2>{t("testimonials.person_1.brand_name")}</h2>
                <p>{t("testimonials.person_1.quote")}</p>
                <p className="small">
                  {t("testimonials.person_1.qualifications")}
                </p>
              </Content>
            </Row>
            <Row style={{ transform: `translateX(${-slideIndex * 100}%)` }}>
              <Square image={testimonial3} />
              <Content>
                <h3>{t("testimonials.header")}</h3>
                <h2>{t("testimonials.person_3.brand_name")}</h2>
                <p>{t("testimonials.person_3.quote")}</p>
                <p className="small">
                  {t("testimonials.person_3.qualifications")}
                </p>
              </Content>
            </Row>
            <Row style={{ transform: `translateX(${-slideIndex * 100}%)` }}>
              <Square image={testimonial4} />
              <Content>
                <h3>{t("testimonials.header")}</h3>
                <h2>{t("testimonials.person_4.brand_name")}</h2>
                <p>{t("testimonials.person_4.quote")}</p>
                <p className="small">
                  {t("testimonials.person_4.qualifications")}
                </p>
              </Content>
            </Row>
            {/*
            <Row style={{ transform: `translateX(${-slideIndex * 100}%)` }}>
              <Square image={testimonial} />
              <Content>
                <h3>{t("testimonials.title")}</h3>
                <h2>{t("testimonials.person_3.brand_name")}</h2>
                <p>{t("testimonials.person_3.quote")}</p>
                <p className="small">
                  {t("testimonials.person_3.qualifications")}
                </p>
              </Content>
            </Row> */}

            <Arrow
              style={{ opacity: slideIndex === 0 ? 0.2 : 1 }}
              $left
              onClick={() => setSlideIndex((p) => Math.max(p - 1, 0))}
            />
            <Arrow
              style={{ opacity: slideIndex === 3 ? 0.2 : 1 }}
              $right
              onClick={() => setSlideIndex((p) => Math.min(p + 1, 3))}
            />
          </TestimonialWrapper>
        </Container>
      </Wrapper2>
    </>
  );
};

export default Testimonials;
