import React from "react";
import styled from "styled-components";
import { Block, Container, PreTitle, Title } from "../components/ui";
import { useTranslation } from "gatsby-plugin-react-i18next";
import robot from "../assets/robot.png";

import Icon16 from "../assets/icons/showcase_16.svg";
import Icon4 from "../assets/icons/showcase_4.svg";
import IconAutomate from "../assets/icons/showcase_automate.svg";
import IconCloud from "../assets/icons/showcase_cloud.svg";

import { Player } from "@lottiefiles/react-lottie-player";
import * as logoWeTechFood from "../assets/lottie/logoWeTechFood.json";
import * as logoWeTechFoodSmall from "../assets/lottie/logoWeTechFood_small.json";

// import TempIcon from "../assets/icons/arrow-right.svg";

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 500px;
  max-width: 1280px;
  margin: 20px auto 20px;
  background: #fff;
  padding: 40px;
`;

const Robot = styled.img`
  max-width: 100%;
  transition: height 0.2s ease, margin 0.2s ease;
  border-left: 0px solid transparent;
  height: 720px;

  @media (max-width: 1280px) {
    height: 600px;
    margin-top: 40px;
  }

  @media (max-width: 1024px) {
    height: 500px;
    margin-top: 60px;
  }

  @media (max-width: 880px) {
    margin-top: 0;
    height: auto;
  }
`;

const RobotScreen = styled(Player).attrs({
  autoplay: true,
  loop: true,
})`
  width: 181px;
  height: 116px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-55%, -47%);

  @media (max-width: 1280px) {
    transform: translate(-55%, -21%);
    width: 154px;
    height: 98px;
  }

  @media (max-width: 1024px) {
    transform: translate(-55%, -2%);
    width: 124px;
    height: 81px;
  }

  @media (max-width: 880px) {
    transform: translate(-55%, -45%);
    width: 184px;
    height: 115px;
  }

  @media (max-width: 715px) {
    transform: translate(-55%, -45%);
    width: 26vw;
    height: 16vw;
  }
`;

const RobotWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 -50px -50px;
  margin-right: -130px;

  @media (max-width: 1280px) {
    margin-right: -110px;
  }

  @media (max-width: 1024px) {
    margin-right: -90px;
  }

  @media (max-width: 880px) {
    margin-right: -70px;
    margin-top: -75px;
  }

  @media (max-width: 715px) {
    margin-right: -75px;
    margin-top: -50px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  overflow: visible;
  gap: min(4vw, 50px);

  padding: 0 min(5vw, 70px);

  @media (max-width: 880px) {
    flex-direction: column;
  }
`;
const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;

  & ${Title} {
    font-size: 80px;
    margin: 0;
    text-align: left;
  }

  & ${PreTitle} {
    text-align: left;
    margin: 0;
  }

  @media (max-width: 880px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5vw;

    & ${Title} {
      text-align: center;
    }

    & ${PreTitle} {
      text-align: center;
    }
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Spider = styled.div`
  min-height: 100%;
  flex: 1;
  align-self: stretch;
  display: flex;
  position: relative;

  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 50%;
    box-sizing: border-box;
  }

  &:before {
    height: 50%;
    left: 0;
    border: 1px solid #c7c7c7;
    border-width: 0 0 1px 0;
  }

  &:after {
    top: 16%;
    left: 50%;
    height: 68%;
    border: 1px solid #c7c7c7;
    border-width: 1px 0 1px 1px;
  }

  @media (max-width: 880px) {
    display: none;
  }
`;

const Note = styled.div`
  text-align: right;
  color: #ccc;
  padding: 0 min(5vw, 70px) 20px;
`;

const keyPoints = [
  {
    label: "Max. Bowls/hour capacity",
    value: "430",
  },
  {
    label: "Possible errors",
    value:
      // <span>
      //   <span style={{ fontSize: "0.7em" }}>+/-</span>0.5
      // </span>
      "0%",
  },
  {
    label: "Bowl consistency per ingredient",
    value: (
      <span>
        <span style={{ fontSize: "0.7em" }}>+</span>0.5%
      </span>
    ),
  },
  {
    label: "Labour Cost Savings*",
    value: "4x",
  },
];

const Automate = () => {
  const { t } = useTranslation();

  return (
    <Block id="work">
      <StyledContainer>
        <PreTitle>{t("showcase.pre_title")}</PreTitle>
        <Title>{t("showcase.title")}</Title>
        <Row>
          <RobotWrapper>
            <Robot src={robot} alt="" />
            <RobotScreen src={[logoWeTechFood, logoWeTechFoodSmall][0]} />
          </RobotWrapper>
          <Spider></Spider>
          <Data>
            {keyPoints.map(({ label, value }, index) => (
              <div key={index}>
                <PreTitle>{label}</PreTitle>
                <Title>{value}</Title>
              </div>
            ))}
          </Data>
        </Row>
        <Note>
          * Considering 150 bowls per day with 4 people working vs LEX.
        </Note>
      </StyledContainer>
    </Block>
  );
};

export default Automate;
