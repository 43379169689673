import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Block, Container } from "../components/ui";
import { useTranslation } from "gatsby-plugin-react-i18next";

import jorge_montejo_perez from "../assets/team/jorge-montejo-perez-t.png";
import roman_salazar from "../assets/team/roman-salazar-t.png";
import guillermo_ortiz_pretel from "../assets/team/guillermo-ortiz-pretel-t.png";
import luis_rodrigo_orellana_ferrufino from "../assets/team/luis-rodrigo-orellana-ferrufino-t.png";
import jose_carlos_montes from "../assets/team/jose-carlos-montes-t.png";
import javier_hernanz from "../assets/team/javier-hernanz-t.png";
import jesus_felipe_espadero from "../assets/team/jesus-felipe-espadero-t.png";
import lucas from "../assets/team/lucas-t.png";
import marius from "../assets/team/marius.png";
import mike from "../assets/team/mike-t.png";
import cecilia from "../assets/team/cecilia.png";
import diana from "../assets/team/diana.png";

import Demium from "../assets/partner_logos/demium.svg";
import EatableAdventures from "../assets/partner_logos/eatable_adventures.svg";
import Nvidia from "../assets/partner_logos/nvidia.svg";
import Aws from "../assets/partner_logos/aws.svg";
import Aecoc from "../assets/partner_logos/aecoc.svg";

const Wrapper = styled(Block)`
  background: #fff;
`;

const StyledContainer = styled(Container)`
  padding-top: 60px;
  padding-bottom: 30px;
`;

const PreTitle = styled.h3`
  color: #2ec4b6;
  margin-bottom: 10px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 800;
  color: #1b2033;
  margin-top: 10px;
  text-align: center;
`;

const ProfileGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1280px;
  margin: 0 auto;

  @media (min-width: 1375px) {
    /* Lets not keep cecilia singled out in 3rd row*/
    /*& > div:nth-child(8) {
      margin-left: 200px;
    }
    & > div:nth-child(9) {
      margin-right: 200px;
    }*/

    /* flex-direction: row-reverse;
    flex-wrap: wrap-reverse; */
  } 

  ${({ main }) =>
    main &&
    `
    @media (max-width: 1030px) {
      & > div:nth-child(2) {
        margin-right: 170px;
        margin-left: 170px;
      }
      /* flex-direction: row-reverse;
      flex-wrap: wrap-reverse; */
    }
  `}
`;

const LogosRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  padding: 30px 0;
  & path {
    transition: fill 0.3s ease;
  }

  & a:not(:hover) {
    & path {
      fill: #373e57;
    }
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

const Team = () => {
  const { t } = useTranslation();
  const [passOrder, setPassOrder] = useState(
    typeof window !== "undefined" && window.innerWidth <= 1030
  );

  useEffect(() => {
    try {
      window
        .matchMedia("(max-width: 1030px)")
        .addEventListener("change", (e) => setPassOrder(e.matches));
    } catch {
      window.addEventListener(
        "resize",
        (e) =>
          typeof window !== "undefined" &&
          setPassOrder(window.innerWidth <= 1030)
      );
    }
  }, []);

  return (
    <Wrapper id="team">
      <StyledContainer>
        <PreTitle>{t("team.pre_title")}</PreTitle>
        <Title>{t("team.title")}</Title>
        <ProfileGrid main>
          <Profile
            image={jorge_montejo_perez}
            name="Jorge Montejo Pérez"
            title={t("team.jorge_montejo_perez.title")}
            bio={t("team.jorge_montejo_perez.bio")}
            style={passOrder ? { order: 2 } : {}}
          />
          <Profile
            image={roman_salazar}
            name="Román Salazar"
            title={t("team.roman_salazar.title")}
            bio={t("team.roman_salazar.bio")}
            style={passOrder ? { order: 1 } : {}}
          />
          <Profile
            image={guillermo_ortiz_pretel}
            name="Guillermo Ortiz Pretel"
            title={t("team.guillermo_ortiz_pretel.title")}
            bio={t("team.guillermo_ortiz_pretel.bio")}
            style={passOrder ? { order: 3 } : {}}
          />
          <Profile
            image={luis_rodrigo_orellana_ferrufino}
            name="Luis Rodrigo Orellana Ferrufino"
            title={t("team.luis_rodrigo_orellana_ferrufino.title")}
            bio={t("team.luis_rodrigo_orellana_ferrufino.bio")}
            style={passOrder ? { order: 4 } : {}}
          />
          <Profile
            image={javier_hernanz}
            name="Javier Hernanz"
            title={t("team.javier_hernanz.title")}
            bio={t("team.javier_hernanz.bio")}
            style={passOrder ? { order: 5 } : {}}
          />
          <Profile
            image={jesus_felipe_espadero}
            name="Jesús Felipe Espadero"
            title={t("team.jesus_felipe_espadero.title")}
            bio={t("team.jesus_felipe_espadero.bio")}
            style={passOrder ? { order: 6 } : {}}
          />
          <Profile
            image={diana}
            name="Diana Corredor Jiménez"
            title={t("team.diana.title")}
            bio={t("team.diana.bio")}
            style={passOrder ? { order: 7 } : {}}
          />
          <Profile
            image={cecilia}
            name="Cecilia Herrejon Gomez"
            title={t("team.cecilia.title")}
            bio={t("team.cecilia.bio")}
            style={passOrder ? { order: 8 } : {}}
          />
        </ProfileGrid>

        <PreTitle>{t("team.advisors")}</PreTitle>
        <br />
        <ProfileGrid>
          <Profile
            image={mike}
            name="Mike Soertsz"
            title={t("team.mike.title")}
            bio={t("team.mike.bio")}
            style={{}}
          />
          <Profile
            image={lucas}
            name="Lucas Garelli"
            title={t("team.lucas.title")}
            bio={t("team.lucas.bio")}
            style={{}}
          />
        </ProfileGrid>

        <PreTitle>{t("team.powered_by")}</PreTitle>
        <LogosRow>
          <a target="_blank" href="https://www.aecoc.es/">
            <Aecoc />
          </a>
          <a target="_blank" href="https://aws.amazon.com/">
            <Aws />
          </a>
          <a target="_blank" href="https://www.nvidia.com/">
            <Nvidia />
          </a>
          <a target="_blank" href="https://www.eatableadventures.com/">
            <EatableAdventures />
          </a>
          <a target="_blank" href="https://demium.com/">
            <Demium />
          </a>
        </LogosRow>
        <br />
      </StyledContainer>
    </Wrapper>
  );
};

const ProfileWrapper = styled.div`
  margin: 0 6px 30px 6px;
`;
const ProfileImage = styled.div`
  width: 280px;
  height: 280px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  ${({ image }) =>
    image && `background: url(${image}) no-repeat center center;`}
  background-size: cover;

  ${({ bio }) =>
    `
    &:hover > div {
      opacity: ${bio !== "" ? 1 : 0.2};
    }
    `}
`;

const ProfileName = styled.div`
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  margin: 15px 0 5px 0;
`;
const ProfileTitle = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 18px;
`;

const BioWrapper = styled.div`
  transition: opacity 0.3s ease;
  opacity: 0;
  background: rgba(0, 0, 0, 0.84);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 25px;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const Profile = ({ image, name, title, bio, style = {} }) => {
  return (
    <ProfileWrapper style={style}>
      <ProfileImage image={image} bio={bio}>
        <BioWrapper>{bio}</BioWrapper>
      </ProfileImage>
      <ProfileName>{name}</ProfileName>
      {title && <ProfileTitle>{title}</ProfileTitle>}
    </ProfileWrapper>
  );
};

export default Team;
