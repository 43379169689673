import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const Content = styled.div`
  flex: 1;
  overflow: hidden;
  display: grid;

  .year {
    display: none;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;

    .year {
      display: inline-block;
      font-weight: 400;
    }
  }
`;

const Slide = styled.div`
  grid-area: 1 / 1 / 1 / 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  min-height: 400px;

  @media (max-width: 600px) {
    min-height: 0;
  }

  justify-content: center;
  padding: 0vw 4vw 3vw;

  & h2 {
    font-weight: 800;
    color: #1b2033;
  }
  transition: transform 0.3s ease, opacity 0.3s ease;

  ${({ position }) =>
    position === "before" &&
    `
    transform: translateY(-40px);
    opacity: 0;
    pointer-events: none;
  `}

  ${({ position }) =>
    position === "after" &&
    `
    transform: translateY(40px);
    opacity: 0;
    pointer-events: none;
  `}

  ${({ position }) =>
    position === "current" &&
    `
    transform: translateY(0);
    opacity: 1;
  `}

  

    @media (max-width: 600px) {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Timeline = styled.div`
  padding: 67px;
  border-right: 1px solid #e7e7e7;
  gap: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 120px;
  width: 275px;

  @media (max-width: 600px) {
    display: none;
  }

  @media (max-width: 1024px) {
    padding-right: 57px;
    padding-left: 57px;
    width: 230px;
  }

  @media (max-width: 800px) {
    padding-right: 20px;
    padding-left: 20px;
    width: 155px;
  }
`;

const WrapperCard = styled.div`
  max-width: min(85vw, 1240px);
  width: 100%;
  min-height: 300px;
  background: #fff;

  border-radius: 18px;
  display: flex;
  flex-direction: row;
  /* margin-bottom: 70px; */
  transition: height 0.3s ease;
  overflow: hidden;

  @media (min-width: 601px) {
    box-shadow: 0 0 0 2000px #ffbf69;
  }
`;

const JourneyWrapper = styled.div`
  max-width: min(85vw, 1240px);
  transition: height 0.3s ease;

  @media (max-width: 600px), (max-height: 580px) {
    position: static;
    transform: translate(0, 0);
  }
`;

const Marker = styled.div`
  position: absolute;
  top: 66px;
  right: 0;
  height: 34px;
  width: 6px;
  background: #ff8c07;
  transition: transform 0.3s ease;
`;

const Button = styled.button`
  background: none;
  border: none;
  outline: none;
  display: block;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  padding: 5px;
  margin: 0;
  width: 100%;
  position: relative;
  text-align: left;
  white-space: nowrap;
  color: #000;

  & .year {
    position: absolute;
    left: -80%;
    top: 0;
    text-align: right;
    margin-right: 5px;
    font-weight: 800;
    padding: 5px;
  }

  @media (max-width: 1024px) {
    & .year {
      position: static;
      font-weight: 400;
      margin-right: 0px;
      &:before {
        content: " (";
        padding-left: 10px;
        margin: 0;
        padding: 0;
      }
      &:after {
        content: ")";
        padding-left: 10px;
        margin: 0;
        padding: 0;
      }
    }
  }

  & > span:first-child {
    transition: transform 0.3s ease;
    display: inline-block;
  }
  &:hover > span:first-child {
    transform: translateX(10px);
  }
`;

const OuterWrapper = styled.div`
  /* position: relative; */

  @media (max-width: 600px), (max-height: 580px) {
    height: auto !important;
  }
`;

const JourneySlider = ({
  header = <></>,
  slides = [
    {
      year: 2020,
      title: "Example title",
      content: "Example slide content.",
    },
    {
      year: 2021,
      title: "Example title 2",
      content: "Example slide content 2.",
    },
  ],
}) => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <OuterWrapper>
      <JourneyWrapper>
        <div>
          <div style={{ overflow: "hidden" }}>{header}</div>
        </div>
        <WrapperCard>
          <Timeline>
            <Marker
              style={{ transform: `translateY(${activeSlide * 100}%)` }}
            />
            {slides.map((slide, i, a) => (
              <Button
                key={i}
                onClick={() => {
                  setActiveSlide(i);
                }}
              >
                <span>{slide.title}</span>
                {(i === 0 || slide.year !== a[i - 1].year) && (
                  <span className="year">{slide.year}</span>
                )}
              </Button>
            ))}
          </Timeline>
          <Content>
            {slides.map((slide, i, a) => (
              <Slide
                key={i}
                position={
                  i < activeSlide
                    ? "before"
                    : i > activeSlide
                    ? "after"
                    : "current"
                }
              >
                <h2>
                  {slide.title}{" "}
                  {(i === 0 || slide.year !== a[i - 1].year) && (
                    <span className="year">, {slide.year}</span>
                  )}
                </h2>

                <div>{slide.content}</div>
              </Slide>
            ))}
          </Content>
        </WrapperCard>
      </JourneyWrapper>
    </OuterWrapper>
  );
};

export default JourneySlider;
