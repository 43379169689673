import React from "react";
import styled from "styled-components";
import { Container, Block, Button } from "../components/ui";
import background from "../assets/cta-meals.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
// import Select from "react-select";
import ScrollContainer from "react-indiana-drag-scroll";

const Wrapper = styled(Block)`
  background: #1b2033;
  padding-top: 50px;
`;

const StyledContainer = styled(Container)`
  padding: 20px 0;
`;

const PreTitle = styled.h3`
  color: #2ec4b6;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 0;
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 800;
  color: #fff;
  margin-top: 10px;
  text-align: center;
`;

const ValuesBody = styled.div`
  max-width: 1024px;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-columns: 1fr 2.7fr; */
  margin: 0 auto 80px;

  /* @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  } */
`;

// const SelectWrapper = styled.div`
//   padding: 0 min(5vw, 60px);
//   margin-bottom: 30px;

//   & > * {
//     border-left: 2px solid #fff;
//   }

//   @media (max-width: 1024px) {
//     display: block;
//   }
// `;

const Menu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-left: 2px solid #ffffff26;

  & li button {
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    text-align: left;
    padding: 0 7% 0 10%;
    cursor: pointer;
    border-left: 2px solid transparent;
    margin-left: -2px;
    transition: border-color 0.5s ease;
    &.active {
      border-left: 2px solid #fff;
      margin-left: -2px;
    }
  }

  flex-direction: row;
  border-left: 0 solid #ffffff26;
  border-bottom: 2px solid #ffffff26;
  margin: 0 auto 60px;
  gap: min(64px, 5vw);
  width: fit-content;
  user-select: none;

  & li button {
    border-left: 0px solid transparent !important;
    margin-left: 0px !important;

    padding: 0;
    padding-bottom: 6px;

    border-bottom: 2px solid transparent;
    margin-bottom: -2px;
    white-space: nowrap;

    &.active {
      border-bottom: 2px solid #fff;
    }
  }
`;

const Content = styled.div`
  display: flex;
  /* padding: 0 min(5vw, 60px); */
  line-height: 34px;
  white-space: pre-wrap;

  font-family: Gilroy;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 1.1em;
  text-align: center;
  color: #ebebeb;

  @media (max-width: 840px) {
    font-size: 6vw;
  }

  ${({ small }) =>
    small &&
    `    
  font-weight: 300 !important;
  font-size: 24px !important;
  line-height: 2.2em !important;
  text-align: center !important;
  margin: 0 auto;
`}
`;

const MissionVisionBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1024px;
  margin: 0 auto 50px;
  gap: 80px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const MissionAndVision = styled.p`
  color: #fff;
  text-align: center;
  font-size: 24px;
`;

const items1 = [
  {
    label: "Our mission",
    content: `Lead the technological transformation of the restaurant business through highly efficient robots and easy to use software.`,
  },
  {
    label: "Our vision",
    content: `A future where people work hand by hand with the best compelling robots and software in the restaurant business`,
  },
];

const items2 = [
  {
    label: "Determined",
    content: `Determined to change the restaurant business with Robotics`,
  },
  {
    label: "FULL",
    content: `We give our most; our energy goes to creating solutions`,
  },
  {
    label: "Proactivity",
    content: `We don’t wait, we take action.`,
  },
  {
    label: "Be a Role model",
    content: `We are committed to set an example`,
  },
  {
    label: "WTFooders",
    content: `We are humble and kind inside and outside the company`,
  },
  {
    label: "Make Impact",
    content: `Focus on the important and not major on the minor.`,
  },
];

const Values = () => {
  const { t } = useTranslation();
  const [activeItem1, setActiveItem1] = React.useState(0);
  const [activeItem2, setActiveItem2] = React.useState(0);
  return (
    <Wrapper>
      <StyledContainer>
        {/* <PreTitle>Company Values</PreTitle>
        <Title>What's important to us</Title> */}
        <ValuesBody>
          <ScrollContainer>
            <Menu>
              {items1.map(({ label }, i) => (
                <li>
                  <button
                    className={activeItem1 == i ? "active" : ""}
                    onClick={() => setActiveItem1(i)}
                  >
                    <PreTitle>{label}</PreTitle>
                  </button>
                </li>
              ))}
            </Menu>
          </ScrollContainer>
          <Content>{items1[activeItem1].content}</Content>
        </ValuesBody>
        <ValuesBody>
          <ScrollContainer>
            <Menu>
              {items2.map(({ label }, i) => (
                <li>
                  <button
                    className={activeItem2 == i ? "active" : ""}
                    onClick={() => setActiveItem2(i)}
                  >
                    <PreTitle>{label}</PreTitle>
                  </button>
                </li>
              ))}
            </Menu>
          </ScrollContainer>
          <Content small>{items2[activeItem2].content}</Content>
        </ValuesBody>
      </StyledContainer>
    </Wrapper>
  );
};

export default Values;
